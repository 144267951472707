import { createRouter, createWebHashHistory } from 'vue-router';
import UpdatesTable from '@/views/UpdatesTable.vue';
import HomePage from '@/views/Home.vue';
import OrganizationsTable from '@/views/Organizations.vue'; 
import { getAuth, onAuthStateChanged } from "firebase/auth";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
        meta: { overflowHidden: true },
    },
    {
        path: '/updatestable',
        name: 'UpdatesTable',
        component: UpdatesTable
    },
    {
        path: '/organizations',
        name: 'OrganizationsTable',
        component: OrganizationsTable
    },
    {
        path: "/signup", component: () => import("@/views/Signup.vue"),
    },
    {
        path: "/login", component: () => import("@/views/Login.vue"),
    },
    {
        path: "/elections", component: () => import("@/views/Elections.vue"),
        meta: { overflowHidden: true },
    },
    {
        path: "/datacenter", component: () => import("@/views/DataCenter.vue"),
        meta: { overflowHidden: true },
    },
    {
        path: "/about", component: () => import("@/views/AboutUs.vue")
    },
    {
        path: "/supportus", component: () => import("@/views/SupportUs.vue")
    },
    {
        path: "/quizzes", component: () => import("@/views/Quiz.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/feed", component: () => import("@/views/Feed.vue"), 
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/devpages", component: () => import("@/views/DevPages.vue"), 
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/sources", component: () => import("@/views/SourcesPage.vue"),
        name: 'SourcesPage',
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
});
router.afterEach((to) => {
    if (to.meta.overflowHidden) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  });

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const removeListener = onAuthStateChanged(
            getAuth(),
            (user) => {
                removeListener();
                resolve(user);
            },
            reject
        )
    })
}

router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (await getCurrentUser()) {
            next();
        } else {
            // Replace alert with a user-friendly notification
            console.warn("Unauthorized access attempt");
            next('/');
        }
    } else {
        next();
    }
});

export default router;
